
import './style.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { useInView } from "framer-motion";
import { useRef } from "react";
// Définir une palette de couleurs personnalisée
const theme = createTheme({
  palette: {
    primary: {
       
            main: '#ffeb3b',
            dark: '#ffcd38',
            contrastText: '#000',
      
    },
  },
});
export default function VenteFlash() {
  const ref2 = useRef(null);
  const isInView2 = useInView(ref2, { once: true });
  const settings2 = {
    autoplay: false,
    autoplaySpeed: 2000,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const shouldEnableSlider = window.innerWidth < 700;
  return (
    <ThemeProvider theme={theme}>
    <div className="flashHom" ref={ref2} style={{ 
            transform: isInView2 ? "none" : "translateY(250px)",
            opacity: isInView2 ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }}>
    {shouldEnableSlider && (
    <Slider {...settings2}>
      <div className="flashCarousel">
      <div className="carouselDivFlash">
         
      <a href={`/nos-petits-prix`}>
                  <img
                    src={require(`../../images/flash home slider/nos-petits-prix.png`)}
                    alt="nos petits prix"
                    className="flashImgHom1"
                  />
           </a>
           <a href={`/-150`}>
                  <img
                    src={require(`../../images/flash home slider/-150.png`)}
                    alt="-150"
                    className="flashImgHom1"
                  />
                  </a>
                  <a href={`/selection-du-mois`}>
                  <img
                    src={require(`../../images/flash home slider/selection-du-mois.png`)}
                    alt="summer"
                    className="flashImgHom1"
                  />
                  </a>
                  <a href={`/vente-flash`}>
                  <img
                    src={require(`../../images/flash home slider/vente-flash.png`)}
                    alt="-100"
                    className="flashImgHom1"
                  />
                  </a>
        
            </div>
            </div>
          </Slider>)}
          {!shouldEnableSlider && (
            <div className='venteflashbig'>
             {flash.map((item, index) => (
        <div className={`images-zoom-container-home`} key={item}>
             <a href={`/${item}`}>
            <img
                src={require(`../../images/flash home slider/${item}.png`)}
                alt={item}
                className="flashImgHom"
                style={{ animationDelay: `${index * 0.6}s` }} // Délai d'animation
            />
            </a>
        </div>
            ))}
            </div>
         )}
    </div>
    </ThemeProvider>
  );
}
const flash = [
  "nos-petits-prix",
  "-150",
  "selection-du-mois",
  "vente-flash",
];