/* eslint-disable react-hooks/exhaustive-deps */
import './style.css';
import React from 'react';
import Footer from '../../Components/Footer';
import flash2 from '../../images/venteFlash.jpeg'
//import Countdown from '../../Components/Countdown';
//import { Button } from '@mui/material';
import ScrollToTop from '../../Components/scrollup'
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
//import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
export default function VenteFlashPage() {
  const theme = createTheme({
    palette: {
      primary: {
         
              main: '#ffeb3b',
              dark: '#ffcd38',
              contrastText: '#000',
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
    <div className='products100' >
       <img
            src={flash2}
            alt='flash'
            className='flashImgHome'
          /> 
        <div className='venteflashCounter'>  
      <h3>coming soon </h3>
  {/*<Countdown/>*/}
      </div>
      
        <ScrollToTop/>       
      {/*  <div className='flashContain'>
      
          {itemData.map((item) => (
            <div className={`images-zoom-container-flash`}>
            
                <a href={`${item.link}`}>
                <img
                  src={require(`../../images/flash/${item.img}.jpg`)}
                  alt={item.img}
                  className='flashImg'
                />
                <Button variant='contained' endIcon={<KeyboardArrowRightIcon/>} size='small' sx={{marginLeft:{lg:'180px',md:'125px',sm:'130px',xs:'55px'},fontSize:{lg:'12px',md:'12px',sm:'10px',xs:'10px'} }}
                color='primary' className='promoButton'>Profiter</Button>
                </a>
              </div>
          
          ))}
   
          
        </div>
*/}
    
      <Footer/>
    </div>
    </ThemeProvider>
  );
}
// eslint-disable-next-line no-unused-vars
const itemData = [  
   {img:"flash1",link:'/produit/electromenager/appareil%20de%20cuission/AIR%20FRYER%204%20LITRES%20NOIR%20et%20CHROME'},
    {img:"flash2",link:'/produit/electromenager/appareil%20de%20cuission/AIR%20FRYER%204%20LITRES%20NOIR%20et%20COOPER'},
    {img:"flash3",link:'/produit/electromenager/appareil%20de%20cuission/AIR%20FRYER%20AEROFRY%203.5L'},
    {img:"flash4",link:'produit/electromenager/appareil%20de%20cuission/AIR%20FRYER%20Shadow%205L'},
    {img:"flash5",link:'/produit/electromenager/appareil%20de%20cuission/AIR%20FRYER%207%20EN%201'},

    {img:"flash6",link:'/produit/electromenager/hygiene%20et%20article%20maison/ASPIRATEUR%20SANS%20SAC%202400W%20ROUGE'},
    {img:"flash7",link:'/produit/electromenager/hygiene%20et%20article%20maison/ASPIRATEUR%20SANS%20SAC%202400W%20COOPER'},
    {img:"flash8",link:'/produit/electromenager/cafe%20et%20petit%20dejeuner/CAFETIERE%201.25L%20INSPIRE%20WHITE'},
    {img:"flash9",link:'/produit/electromenager/cafe%20et%20petit%20dejeuner/MOULIN%20A%20CAFE%20REGLABLE%20140W'},
    {img:"flash10",link:'/produit/electromenager/cafe%20et%20petit%20dejeuner/cafetiere%20filtre%201.25%20L'},
    {img:"flash11",link:'/produit/electromenager/cafe%20et%20petit%20dejeuner/Machine%20a%20Cafe%20Expresso%20et%20Cappuccino'},
    {img:"flash12",link:'/produit/electromenager/cafe%20et%20petit%20dejeuner/Machine%20a%20cafe%20digitale'},

    {img:"flash13",link:'/produit/electromenager/cafe%20et%20petit%20dejeuner/Machine%20a%20cafe%20a%20capsule'},
    {img:"flash14",link:'/produit/electromenager/cafe%20et%20petit%20dejeuner/MOULIN%20A%20CAFE'},
  ];