/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import BestProductCard from "../ProductCardSlide";
import UseFetch from "../../hook/useFetch";
import "./style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
export default function ProductBrand({ currentProduct, brand }) {
  const {data,loading}=UseFetch("https://ebuy-backend-two.vercel.app/api/article");
  const produits = Array.from(data).reverse();
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [currentCards, setCurrentCards] = useState([]);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage(5);
      } else if (windowWidth >= 700) {
        setItemsPerPage(4);
      } else {
        setItemsPerPage(2);
      }
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  useEffect(() => {
    const filteredProducts = produits
     
      .filter((produit) => produit.brand === brand);

    const shuffledProducts = shuffleArray(filteredProducts);

    const selectedProducts = shuffledProducts.slice(0, itemsPerPage*3);

    setCurrentCards(selectedProducts);
  }, [ currentProduct, itemsPerPage, brand]);

  // Fonction pour mélanger un tableau
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-arrow custom-prev" onClick={onClick}>
        <ArrowBackIosIcon />
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-arrow custom-next" onClick={onClick}>
        <ArrowForwardIosIcon />
      </div>
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: itemsPerPage,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };
  return loading ? (
    <div className="loader"></div>
    ) : (
    <Slider {...settings}>
    {currentCards.map((produit, index) => (
      <div key={produit.article + index} className="bestProduct">
        <BestProductCard
          key={produit.article + index}
          categorie={produit.categorie}
          article={produit.article}
          prix={produit.prix}
          prixPromo={produit.prixPromo}
          prixFlash={produit.prixFlash}
          colors={produit.colors}
          brand={produit.brand}
          famille={produit.famille}
          subcategorie={produit.subcategorie}
          stock={produit.stock}
        />
      </div>
    ))}
  </Slider>
  );
}
