export const cardData = [
  {
    title: 'electromenager',
    sousFamille: [
      { nom: 'preparation culinaire', sousFamilles: [] },
      { nom: 'appareil de cuission', sousFamilles: [] },
      { nom: 'cafe et petit dejeuner', sousFamilles: [] },
      { nom: 'hygiene et article maison', sousFamilles: [] },
      { nom: 'glaciere', sousFamilles: [] }
    ]
  },
  {
    title: 'luminaire',
    sousFamille: [
      { nom: 'suspensions luminaires', sousFamilles: [] },
      { nom: 'plafonniers et lampes plafond', sousFamilles: [] },
      { nom: 'lampes de table', sousFamilles: [] },
      { nom: 'spots', sousFamilles: [] },
      { nom: 'ampoules', sousFamilles: []},
      { nom: 'spots encastrables', sousFamilles: []},
    ]
  },
  {
    title: 'mobilier',
    sousFamille: []
  },
  {
    title: 'beaute & sante',
    sousFamille: [
      { nom: 'bien être', sousFamilles: [] },
      { nom: 'coiffure', sousFamilles: [] },
      { nom: 'epilation', sousFamilles: [] },
      { nom: 'rasoir et tendeuse', sousFamilles: [] },
      { nom: 'soin visage et corps', sousFamilles: [] }
    ]
  },
  {
    title: 'mode & accessoires',
    sousFamille: [
      {
        nom: 'accessoires',
        sousFamilles: ['montres enfants', 'montres hommes', 'montres femmes']
      }
    ],
    
  },
  {
    title: 'informatique & gaming',
    sousFamille: [
      { nom: 'souris', sousFamilles: [] },
      { nom: 'casque', sousFamilles: [] },
     { nom: 'enceinte & radio reveil', sousFamilles: [] },
      { nom: 'accessoire', sousFamilles: [] },
      { nom: 'cable', sousFamilles: [] },
      { nom: 'cle usb', sousFamilles: [] },
      { nom: 'lecteur de carte et micro SD', sousFamilles: [] },
    ]
  },
];
