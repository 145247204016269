import "./style.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material";

export default function BestProductCard({
  index,
  famille,
  article,
  categorie,
  subcategorie,
  prixPromo,
  prixFlash,
  prix,
  colors,
  brand,
  reference,
  stock
}) {
  const categoriee = article.split('-')[0];
  const articleName = article.split('-')[1];
  const articlImg = article.replace(/-/g, ' ');
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 900,
        lg: 1200,
      },
    },
  });

  
  return (
    <ThemeProvider theme={customTheme}>
      <div className="cardBest">
        <Card
          className="card"
          sx={{
            height: { lg: "300px", md: "300px", sm: "240px", xs: "220px" },
            width: { lg: "18vw", md: "22.5vw", sm: "22.5vw", xs: "38vw" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 1
          }}
          elevation={3}
        >
          {categorie === categoriee ? (
          <a className="cardMediaLink" href={`/produit/${famille}/${categoriee}/${articleName}`}>
            
              <img
                className="BestcardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categoriee)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
                title={articleName}
                alt={articleName}
              />
          
          </a>
            ) :categoriee === subcategorie ? (
              <a className="cardMediaLink" href={`/produit/${famille}/${categorie}/${subcategorie}/${articleName}`}>
                
                  <img
                    className="BestcardMedia"
                    src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categorie)}%2F${decodeURIComponent(subcategorie)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
                    title={articleName}
                    alt={articleName}
                  />
              
              </a>
                ):(<></>)}
          <CardContent
            sx={{
              borderTop: 'solid rgba(0, 0, 0, 0.190) 1px',
              width: "95%",
              height: "auto",
              position: 'absolute',
              bottom: 0,
              padding: 1,
              marginTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="articleDetail" style={{ fontWeight: 'bold' }}>
                {articleName}
              </span>
              <span className="articleDetail">
                {brand}
              </span>
              {(prixFlash?(
                <>
                 <span className="articleDetail" style={{textDecoration:'line-through',textDecorationColor:'red'}}>
                 { prix.toFixed(3)} TND
              
                </span>
                   <span className="articleDetail">
                   {prixFlash.toFixed(3)} tnd
                   </span></>):prixPromo?(
                <>
                 <span className="articleDetail" style={{textDecoration:'line-through',textDecorationColor:'red'}}>
                 { prix.toFixed(3)} TND
              
                </span>
                   <span className="articleDetail">
                   {prixPromo.toFixed(3)} tnd
                   </span></>):(
              <span className="articleDetail">
                {prix.toFixed(3)} TND
              </span>))}
            </div>
           
          </CardContent>
        </Card>
      </div>
    </ThemeProvider>
  );
}
